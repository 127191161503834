import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '@popperjs/core';
import 'aos/dist/aos.css';
import AOS from 'aos';

const jquery = require("jquery");
$ = window.$ = window.jQuery = jquery;

const countDownDate = new Date("Oct 13, 2024 09:00").getTime();
const x = setInterval(function() {
  const now = new Date().getTime();
  const distance = countDownDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  document.getElementById("wedding-day").innerHTML = days + "<br>Hari";
  document.getElementById("wedding-hour").innerHTML = hours + "<br>Jam";
  document.getElementById("wedding-min").innerHTML = minutes + "<br>Menit";
  document.getElementById("wedding-sec").innerHTML = seconds + "<br>Detik";

  if (distance < 0) {
    clearInterval(x);
    document.getElementById("wedding-time").innerHTML = "Acara Sudah Selesai";
    document.getElementById("wedding-day").innerHTML = "";
    document.getElementById("wedding-hour").innerHTML = "";
    document.getElementById("wedding-min").innerHTML = "";
    document.getElementById("wedding-sec").innerHTML = "";
  }
}, 1000);

const invitationButton = document.getElementById("btn-invt");

const openInvitation = function() {
  document.querySelector('.welcome').className +=  ' hidden'
  document.querySelector('.wedding').className +=  ' show'
  document.getElementById("audio").play();
};

const getParams = function(key) {
  const encParams = window.location.search.substring(1)
  const decodedParams = encParams && atob(encParams)
  const url = `${window.location.origin}?${atob(encParams).split(' ').join('%20')}`
  const query = new URL(url);
  if (!query.searchParams.get(key)  || !encParams) return null;
  return query.searchParams.get(key)
}

window.addEventListener('load', function() {
  invitationButton.addEventListener("click", openInvitation);

  const name = getParams('name')
  if (name) {
    const content = `Yang Terhormat, \n ${name}`
    document.getElementsByClassName('invited')[0].innerText = content;
  }
})

AOS.init();

$('.slider').slick({
  centerMode: true,
  centerPadding: '325px',
  slidesToShow: 1,
  responsive: [
    {
      breakpoint: 1033,
      settings: {
        dots: true,
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]
});
